import React, { useState } from "react"
// import { Link } from "gatsby-plugin-modal-routing"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../styles/Container"
import { PrimaryButton } from "../styles/Button"
import TiltCard from "../components/partial/TiltCard"
import NoiseBg from "../images/noises.png"

const About = () => {
  const [state, setState] = useState({
    fullname: "",
    topic: "",
    message: "",
  })

  const handleChange = event => {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
    console.log(state)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    console.log("Submit test form")
    const form = event.target
    try {
      const { fullname, topic, message } = state
      await fetch("/.netlify/functions/test", {
        method: "POST",
        body: JSON.stringify({
          fullname,
          topic,
          message,
        }),
      }).then(response => {
        console.log(response.body)
        // navigate(form.getAttribute("action"))
      })
      // resetForm()
      // courseAdded()
      // setTimeout(() => {
      //   form.submit()
      // }, 2000)
    } catch (err) {
      console.error(err)
    }
  }
  const { fullname, topic, message } = state
  return (
    <Layout>
      <br />
      <br />

      <form
        // action="/.netlify/functions/test"
        // method="POST"
        onSubmit={handleSubmit}
      >
        <label htmlFor="fullname">Full name :</label>
        <input
          type="text"
          name="fullname"
          value={fullname}
          onChange={handleChange}
        />
        <input type="text" name="topic" value={topic} onChange={handleChange} />
        <input
          type="text"
          name="message"
          value={message}
          onChange={handleChange}
        />
        <button>Send</button>
      </form>
      {/* <SEO title="About" /> */}
      <Banner>
        <Container>
          <BannerInner>
            <div className="title h1">Lorem ipsum set</div>
            <Breadcrumb>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/">Work</Link>
              </li>
              <li>
                <span>Lorem ipsum set</span>
              </li>
            </Breadcrumb>
          </BannerInner>
        </Container>
      </Banner>
      <Container>
        {/* <Link to="/work/eliquid-and-co" asModal>
          <h2>Launch Modal page</h2>
        </Link> */}
        <PageContent>
          <PageContentIntro>
            <div className="description">
              <h1>Eliquid And Co, E-cigarette leader</h1>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dignissimos voluptatibus illum aliquid architecto, ipsum
                similique animi eos veritatis aliquam soluta libero quasi, hic
                inventore ducimus minus, voluptatum adipisci error labore.
              </p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dignissimos voluptatibus illum aliquid architecto, ipsum
                similique animi eos veritatis aliquam soluta libero quasi, hic
                inventore ducimus minus, voluptatum adipisci error labore.
              </p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Dignissimos voluptatibus illum aliquid architecto, ipsum
                similique animi eos veritatis aliquam soluta libero quasi, hic
                inventore ducimus minus, voluptatum adipisci error labore.
              </p>
            </div>
            <div className="media">
              <TiltCard className="media__inner">
                <img src="https://source.unsplash.com/500x350" alt="Lorem" />
              </TiltCard>
              <div className="media__action">
                <PrimaryButton as={Link} to="#" target="_blank">
                  View screenshots
                </PrimaryButton>
                <PrimaryButton as={Link} to="#" target="_blank">
                  Visit website
                </PrimaryButton>
              </div>
            </div>
          </PageContentIntro>

          <CaseInfo>
            <div className="item">
              <span className="name">Role :</span>
              <span className="value">Front-end engineer</span>
            </div>
            <div className="item">
              <span className="name">Company :</span>
              <span className="value">Groupe Ipsum ltd</span>
            </div>
            <div className="item">
              <span className="name">Field :</span>
              <span className="value">eCommerce</span>
            </div>
            <div className="item">
              <span className="name">Mission :</span>
              <span className="value">
                Website redo, importing existing data
              </span>
            </div>
            <div className="item">
              <span className="name">Tech-stack :</span>
              <span className="value">Symfony, Scss, Gulp</span>
            </div>
          </CaseInfo>
          <h2>
            Sectetur adipisicing elit. Vero quia optio beatae, doloribus saepe
            voluptatibus, incidunt
          </h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quis
            eaque ipsum! Nobis voluptatibus voluptatum, quibusdam nostrum,
            dolorum repellat tempora soluta veritatis necessitatibus vitae
            sapiente sint voluptate optio, earum nam.
          </p>
          <img src="https://source.unsplash.com/1200x400" alt="Lorem" />
          <p>
            F sectetur adipi Lorem ipsum dolor sit amet consectetur adipi sicing
            elit. Repellat consectetur facere, magnam quo cupiditate, ad eius
            molestias mollitia atque eum suscipit quibusdam eos quia ea nihil?
            Hic illo aspernatur sint! Magnam quidem adipisci saepe conseq uuntur
            pariatur inventore repellat dicta veniam suscipit accus antium
            temporibus commodi aut dignissimos in, dolore eius nesciunt, tenetur
            animi ut nulla? Illo atque voluptas repudiandae labore ducimus.
            sicing elit. Culpa quis eaque ipsum! Nobis volu ptatibus volu
            ptatum, quib usdam nostrum, dolorum repellat tempora soluta
          </p>
          <h2>Epellat dicta veniam suscipit accus antium temporibus commodi</h2>
          <p>
            Voluptatum, Lorem ipsum dolor sit amet consectetur. quibusdam
            nostrum, dolorum repellat tempora soluta veritatis necessitatibus
            vitae sapiente sint voluptate optio, earum nam.
          </p>
          <ul>
            <li>
              Perferendis suscipit consectetur accusamus aliquam, eaque sit
              aspernatur rem quidem voluptates modi voluptatum ab blandi
            </li>
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et, eius
              perferendis suscipit consectetur accusamus aliquam, eaque sit
            </li>
            <li>
              Sit amet consectetur adipisicing elit Lorem ipsum dolor, . Et,
              eius aspernatur rem quidem voluptates modi voluptatum ab blandi
            </li>
          </ul>
        </PageContent>
      </Container>
    </Layout>
  )
}

const CaseInfo = styled.div`
  max-width: 500px;
  margin: 0 auto 4rem;
  border: 1px solid ${({ theme }) => theme.darkBorder};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.mainColor};
  .item {
    border-bottom: 1px solid ${({ theme }) => theme.darkBorder};
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    span {
      flex: 1;
    }
  }
`
const PageContentIntro = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 2rem;
  align-items: flex-start;
  margin-bottom: 6rem;
  .description {
    h1 {
      font-size: 40px;
      letter-spacing: 0.6px;
      line-height: 1.1;
    }
  }
  .media {
    &__inner {
      background: ${({ theme }) => theme.skinnyColor};
      border-radius: 6px;
      transform-style: preserve-3d;
      &:hover {
        img {
          transform: translate(25px, -25px) translateZ(60px);
        }
      }
      img {
        margin: 0;
        margin-left: auto;
        border-radius: 4px;
        transform: translate(15px, -15px);
        transition: 0.3s !important;
      }
    }
    &__action {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1.5rem 0;
    }
  }
`

const PageContent = styled.div`
  padding: 4rem 0;
  p,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-bottom: 1rem;
  }
  img,
  ul,
  p {
    margin-bottom: 1.5rem;
  }
  p {
    line-height: 1.6;
  }
  ul {
    li {
      position: relative;
      display: flex;
      padding-left: 2rem;
      &::before {
        content: "";
        flex-shrink: 0;
        width: 2rem;
        height: 1px;
        background: ${({ theme }) => theme.textColor};
        margin: 14px 10px 0 0;
      }
    }
  }
`

const Banner = styled.div`
  padding: 3rem 0;
  /* background-color: ${({ theme }) => theme.mainColor}; */
  background-color: #000;
  background-image: url(${NoiseBg});
  background-position: center right;
  background-size: cover;
  border-bottom: 1px solid ${({ theme }) => theme.darkBorder};
  border-top: 1px solid ${({ theme }) => theme.darkBorder};
  /* margin-bottom: 3rem; */
  /* margin-bottom: 60rem; */
  .title {
    font-size: 40px;
  }
`

const BannerInner = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
`
const Breadcrumb = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  flex-shrink: 0;
  margin: 1rem 0;

  li {
    &:not(:first-child) {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        background: currentColor;
        border-radius: 100%;
        display: inline-block;
      }
    }
    a,
    span {
      font-size: 14px;
      padding: 3px 0;
    }
    a {
      &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.secondaryColor};
      }
    }
    span {
      border-bottom: 1px solid ${({ theme }) => theme.secondaryColor};
    }
  }
`

export default About
